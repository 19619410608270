import React from "react";
import PropTypes from "prop-types"
import styled, {css} from "styled-components";
import { Link } from "gatsby";

const LinksToDisplay = 9

const Container = styled.ul`
  display: flex;
  margin: 0;
  align-self: center;
  flex-wrap: wrap;
`

export const calculateDisplayedNumbers = (currentPage, totalPages) => {
  const temp = []
  let min, max
  if (totalPages >= LinksToDisplay) {
    max = currentPage + Math.floor(LinksToDisplay / 2)
    min = currentPage - Math.ceil(LinksToDisplay / 2)
    if (min <= 0) {
      min = 0
      max = LinksToDisplay
    }
    if (max >= totalPages) {
      max = totalPages
      min = totalPages - LinksToDisplay
    }
    for (let i = 1; i <= LinksToDisplay; i++) {
      temp[i] = min + i
    }
  } else {
    for (let i = 1; i <= totalPages; i++) {
      temp[i] = i
    }
  } // [2,3,5,6,7]
  temp.shift()
  return temp
}

const StyledPaginationLink = styled.li`
  display: grid;
  place-items: center;
  margin: 0 -0.5px;
  border: 1px solid #ddd;
  box-sizing: border-box;

  :first-child {
    border-radius: 4px 0 0 4px;
  }
  :last-child {
    border-radius: 0 4px 4px 0;
  }

  a {
    text-decoration: none;
    color: ${({theme}) => theme.color.indigo400};
    line-height: 1.428571429;
    padding: 6px 12px; 
  }

  :hover {
    background-color: #eee;
  }

  ${({active}) => active && css`
    background-color: ${({theme}) => theme.color.indigo400};
    border: none;
    margin: 0;
    padding: 0 1px;
    a {
      color: white;
    }
  `}
`


const PaginationLink = ({to, children, active}) => (
  <StyledPaginationLink active={active}>
    <Link to={to}>{children}</Link>
  </StyledPaginationLink>
)

PaginationLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any,
  active: PropTypes.bool
}

const Pagination = ({currentPage, totalPages, relativePath, ...rest}) => {

  const nextPage = currentPage + 1
  const prevPage = currentPage - 1
  const displayedNumbers = calculateDisplayedNumbers(currentPage, totalPages)

  return (
    <Container {...rest}>
      {currentPage !== 1 && currentPage !== 2 && (
        <PaginationLink
          to={relativePath}
        >
          First
        </PaginationLink>
      )}
      {currentPage !== 1 && (
        <PaginationLink
          to={prevPage === 1 ? relativePath : `${relativePath}/${prevPage}`}
        >
          Previous
        </PaginationLink>
      )}

      {displayedNumbers.map((v, i) => {
        return (
          <PaginationLink
            key={`pagination-${i}`}
            to={v === 1 ? relativePath : `${relativePath}/${v}`}
            active={v === currentPage}
          >
            {v}
          </PaginationLink>
        )
      })}

      {currentPage !== totalPages && (
        <PaginationLink
          to={`${relativePath}/${nextPage}`}
        >
          Next
        </PaginationLink>
      )}
      {currentPage !== totalPages && currentPage !== totalPages - 1 && (
        <PaginationLink
          to={`${relativePath}/${totalPages}`}
        >
          Last
        </PaginationLink>
      )}
    </Container>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  relativePath: PropTypes.string
}

export default Pagination