import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Image from "@components/image"
import Grid from "@components/elements/grid"
import Heading from "@components/elements/heading"
import BlogHtml from "@components/html"
import Flex from "@components/elements/flex"
import { processImages } from "@utils/process-images"
import BlogCard from "@components/blog/card"
import Pagination from "@components/pagination"
import { slugify } from "@utils/slugify"
import Div from "@components/elements/div"

const AuthorPage = ({ data, pageContext }) => {
  const images = processImages(data.images.edges)
  const author = data.authors
  const image = author.profile_picture

  return (
    <Layout
      canonical={`/author/${author.slug ?? slugify(author.name)}`}
      image={image?.cdn}
      body={author.body}
      date_updated={author.date_updated}
      title={author.name}
      description={author.summary}
    >
      <article>
        <Flex gap="4rem" margin="2rem 0 0">
          <Flex gap="2rem" center>
            <Div
              width="min-content"
              height="min-content"
              overflow="hidden"
              borderRadius="100%"
              margin="0 auto"
            >
              <Image file={image} height={200} width={200} />
            </Div>
            <Heading>{author.name}</Heading>
            {author.body && <BlogHtml parseElements html={author.body} />}
            <Flex
              flexFlow="row"
              alignItems="flex-start"
              width="max-content"
              gap="1rem"
              margin="0 auto"
            >
              <a href={author.twitter}>
                <Image
                  height={50}
                  width={50}
                  file={images["18e0a912-d447-4e47-bc07-e6b9a2c47d48"]}
                />
              </a>
              <a href={author.linkedin}>
                <Image
                  height={50}
                  width={50}
                  file={images["6fd0c17c-a483-4cd1-8ef3-bd2090cef340"]}
                />
              </a>
            </Flex>
          </Flex>
          <Grid
            columns="repeat(auto-fill, minmax(280px, 1fr))"
            gap="40px"
            margin="0 0 60px"
          >
            {data.allBlog.edges.map(({ node: blog }) => {
              const date = new Date(blog.date.replace(/-/g, "/"))
              const dateString = date.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })
              return (
                <BlogCard
                  key={blog.id}
                  image={blog.image}
                  title={blog.title}
                  link={"/" + blog.slug}
                  date={dateString}
                />
              )
            })}
          </Grid>
          {pageContext.numPages > 1 && (
            <Pagination
              style={{ marginBottom: "80px" }}
              currentPage={pageContext.currentPage}
              relativePath={`/author/${slugify(author.name)}`}
              totalPages={pageContext.numPages}
            />
          )}
        </Flex>
      </article>
    </Layout>
  )
}

AuthorPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default AuthorPage

export const pageQuery = graphql`
  query AuthorPageQuery($limit: Int!, $page: Int!, $id: String!) {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "18e0a912-d447-4e47-bc07-e6b9a2c47d48"
            "6fd0c17c-a483-4cd1-8ef3-bd2090cef340"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
    allBlog(
      limit: $limit
      skip: $page
      filter: {
        status: { eq: "published" }
        authors: { elemMatch: { id: { eq: $id } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          image {
            cdn
            placeholder
          }
          title
          date
          slug
        }
      }
    }
    authors(id: { eq: $id }) {
      body
      profile_picture {
        cdn
        placeholder
      }
      twitter
      linkedin
      name
      date_updated
      slug
    }
  }
`
